<script>
import { ref } from '@vue/composition-api'
import {
  VAutocomplete, VTextField, VSelect, VTextarea,
} from 'vuetify/lib/components'
import DateRangePickers from '@core/components/xw-form/DateRangePickers.vue'
import RideoTag from '@/@core/components/xw-form/RideoTag.vue'
import VText from '@/@core/components/xw-form/Text.vue'
import { compareOptions } from '@core/utils/options'
import useAppConfig from '@core/@app-config/useAppConfig'
import { can } from '@core/utils/useAccess'

export default {
  name: 'SearchForm',
  components: {
    VAutocomplete, VTextField, DateRangePickers, VSelect, VTextarea, RideoTag, VText,
  },
  props: {

    // form 配置列表
    formConfig: {
      type: Array,
      default: () => [],
    },

    // 是否为预览
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const form = ref({})
    const formRef = ref()

    const { isDark } = useAppConfig()

    let datePicksKeys

    const initFormValue = () => {
      const initFormData = {}
      datePicksKeys = []
      props.formConfig.forEach(item => {
        // 日期选择搜索字段对应多个key
        if (Array.isArray(item.searchKey)) {
          item.searchKey.forEach((key, index) => {
            initFormData[key] = (item.initValue && item.initValue[index]) || null
          })
          datePicksKeys.push(item.props)
        }

        initFormData[item.props] = item.initValue || item.initValue === 0 ? item.initValue : null

        if (item.slotCompare) {
          initFormData[item.slotCompare.props] = item.slotCompare.initValue ? item.slotCompare.initValue : compareOptions[0].value
        }
      })

      return initFormData
    }

    form.value = initFormValue()

    const getFormData = () => {
      // 处理别名search key问题
      const hasSearchKeyItems = props.formConfig.filter(x => x.searchKey) || []
      const searchKeyMap = {}

      hasSearchKeyItems.forEach(item => {
        searchKeyMap[item.props] = item.searchKey
      })

      Object.keys(searchKeyMap).forEach(key => {
        if (Array.isArray(searchKeyMap[key])) {
          searchKeyMap[key].forEach((item, index) => {
            form.value[item] = form.value[key] || form.value[key] === 0 ? form.value[key][index] : null
          })
        } else {
          form.value[searchKeyMap[key]] = form.value[key]
        }
      })

      // 删除时间选择的key
      const resParams = { ...form.value }
      datePicksKeys.forEach(key => {
        delete resParams[key]
      })

      return resParams
    }

    const handleChange = (key, value) => {
      emit('change', { key, value })
    }

    const validate = () => formRef.value.validate()

    const getFormDom = () => formRef.value

    const onSubmit = () => {
      emit('submit', getFormData())
    }

    return {
      valid: ref(true),
      form,
      formRef,
      isDark,
      compareOptions: ref(compareOptions),
      getFormData,
      can,
      handleChange,
      getFormDom,
      validate,
      onSubmit,
    }
  },
}
</script>
<template>
  <v-form
    ref="formRef"
    v-model="valid"
    lazy-validation
    class="form-wrap"
    :class="isDark ? 'them--dark' : 'them--light'"
    :disabled="!canEdit"
    @submit.prevent="onSubmit"
  >
    <div
      v-for="(item) in formConfig"
      :key="item.props"
      class="form-item"
    >
      <template v-if="!item.auth || can(item.auth)">
        <label
          class="form-label"
          :title="item.label"
        >
          <span
            v-if="item.required"
            class="text--danger"
          >*</span>
          {{ item.label }}
        </label>
        <div
          v-if="canEdit && item.element !== 'VText'"
          class="form-input"
        >
          <component
            :is="item.element"
            v-if="!item.slots"
            v-model.trim="form[item.props]"
            :value="form[item.props]"
            :clearable="item.clearable === undefined ? true : item.clearable"
            :placeholder="item.placeholder"
            label=""
            dense
            hide-details="auto"
            outlined
            single-line
            v-bind="item"
            :disabled="!canEdit|| item.disabled"
            :class="item.slotCompare ? 'form-compare': ''"
            @input="(value) => item.needInt ? form[item.props] = value.replace(/^(0+)|[^\d]+/g,'') : (typeof value === 'String' ? form[item.props] = value.trim() : value)"
            @change="(value) => {handleChange(item.props, value)}"
          >
            <template #selection="{ item: itemChild, index: indexChild }">
              <span v-if="indexChild === 0">
                <span>{{ itemChild.text || itemChild[item.itemText] || itemChild }}</span>
              </span>
              <span
                v-if="indexChild === 1"
                class="grey--text text-caption"
              >
                (+{{ form[item.props].length - 1 }} others)
              </span>
            </template>
          </component>
          <slot
            v-else
            :name="item.slots"
            :row="form[item.props]"
          ></slot>
          <slot :name="item.appendSlots"></slot>
        </div>
        <div
          v-else-if="!item.textSlots"
          class="form-text"
        >
          <VText
            v-if="!item.isLink"
            :value="item.viewText ? item.viewText() : form[item.props]"
          ></VText>
          <div
            v-else
            class="overflow"
            style="width:300px"
          >
            <a
              v-if="form[item.props]"
              :href="form[item.props]"
              target="_blank"
            >{{ form[item.props] }}</a><span v-else>-</span>
          </div>
        </div>
        <slot
          v-else
          :name="item.textSlots"
          :row="form[item.props]"
        ></slot>
      </template>
    </div>
  </v-form>
</template>
<style lang="scss" scoped>
.form-wrap{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 12px;
  .form-item{
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    margin-right: 20px;
    flex: 1;
    .form-input{
      flex: 1;
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-text-field.v-text-field--solo .v-input__control{
        min-height: 40px!important;
      }
      ::v-deep .v-input__control .v-input__slot{
        min-height: 40px!important;
        width: 100%;
        box-sizing: border-box;
        .v-input__prepend-inner{
          margin-top: 0!important;
          .v-input{
            width: 92px!important;
          }

        }

        .v-input__append-inner{
          display: flex;
          align-items: center;
        }
        .v-select__selections{
          font-size: 14px;
        }
      }
      ::v-deep .v-text-field__slot{
        font-size: 14px;
      }
      ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner .v-input__icon--append{
        margin-top: 0;
      }
      ::v-deep .v-select.v-input--dense .v-select__selection--comma{
        margin: 3px 4px 3px 0;
      }
    }
    .form-text{
      font-size: 14px;
      padding:7px 0 0 0;
      flex: 1;
      word-break: break-all;
      overflow: hidden;
    }
    .form-label{
      width: 144px;
      padding: 10px 8px 0 30px;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      white-space: wrap; //不换行
      overflow: hidden; //隐藏溢出
      text-overflow: ellipsis; //省略号
      display: -webkit-box; //-webkit-box是兼容IOS和老版本Android浏览器的方式
      -webkit-line-clamp: 2; //限制行数
      -webkit-box-orient: vertical; //垂直方向排列
      position: relative;
      span{
        position: absolute;
        left: 20px;
      }
      .text--danger{
        color: var(--v-error-base)
      }
    }
  }
  .form-compare{
    ::v-deep .v-text-field__slot{
      width: 115px;
    }
  }
}
.form-wrap.them--dark{
  box-shadow: inset 0px -1px 0px 0px rgba(232, 233, 236, 0.1);
}
</style>
