// 获取用户角色
const userData = JSON.parse(localStorage.getItem('userData')) || {}
const userRoles = userData.role

export const can = roleList => {
  for (let i = 0; i < userRoles.length; i += 1) {
    if (roleList.includes(userRoles[i])) return true
  }

  return false
}

export const canLike = roleList => {
  for (let i = 0; i < userRoles.length; i += 1) {
    for (let j = 0; j < roleList.length; j += 1) {
      if (userRoles[i].includes(roleList[j])) return true
    }
  }

  return false
}

export const editAuth = (userId = '', role = []) => {
  const roleList = ['超级管理员', ...role]

  // 超级管理员可以编辑
  for (let i = 0; i < userRoles.length; i += 1) {
    if (roleList.includes(userRoles[i])) return true
  }

  // 判断是否为自己
  if (userId === userData?.user?.userId) return true

  return false
}
