<script>
export default {
  name: 'AppTitle',
  computed: {
    title() {
      return this.$route.meta?.pageTitle || ''
    },
    subPageTitle() {
      return this.$route.query?.subPageTitle || ''
    },
  },
}
</script>

<template>
  <div style="display: flex; align-items: center">
    <v-icon
      v-if="subPageTitle"
      class="mr20"
      style="margin-left: -20px"
      @click="$router.go(-1)"
    >
      mdi-chevron-left
    </v-icon>
    <div
      v-show="title"
      class="text-h7 font-weight-black title-line"
    >
      {{ title }}<span v-if="subPageTitle">{{ subPageTitle }}</span>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.title-line {
  position: relative;
  color: #000;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 6px;
    height: 100%;
    background-color: var(--v-primary-base);
  }
  span{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.50);
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
    &::before{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.10);
    }
  }
}
</style>
