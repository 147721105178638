import { getDataWithKey } from '@core/utils/dataProcess'
import { modalFail } from '@core/utils/prompt'
import axios from '@axios'
import config from '../../../config'

export const debounce = (fn, interval) => {
  let timeout = null // 创建一个标记用于表示是否存在定时器（有定时器表示在防抖中，通过闭包保存该标记）

  return (...args) => {
    clearTimeout(timeout) // 防抖函数触发时先清除之前的定时器
    timeout = setTimeout(() => { // 清除之前的定时器后重新创建定时器定时，只有在interval时间内不触发防抖函数才会执行该防抖函数
      fn.apply(this, ...args) // 通过apply传入参数
    }, interval)
  }
}

export const throttle = (fn, interval) => {
  let canRun = true // 创建一个标记用于表示是否可以运行（不能运行表示在节流中，通过闭包保存该标记）

  return (...args) => {
    if (!canRun) return // 在函数开始就判断是否能运行，不能就直接return（还在节流中）
    canRun = false // 若已经超过节流interval则可运行，先设置标记为不能运行
    setTimeout(() => { // 设置定时器并传入interval，即在interval时间内为节流时间
      fn.apply(this, ...args) // 通过apply传入参数
      canRun = true // 函数执行后则重新设置标记为可运行
    }, interval)
  }
}

export function fetchOptions(suffix, dataSrc, options) {
  const opts = options || {}
  const {
    params, formatter, key, url, targetKey = 'data.data',
  } = opts

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.serviceUrl}${suffix}` || url, {
        params,
      })
      .then(response => {
        if (response.data.status === 200) {
          const data = getDataWithKey(response, targetKey)
          if (formatter) data?.map(formatter)
          // eslint-disable-next-line no-param-reassign
          dataSrc[key] = data
          resolve(response.data)
        } else modalFail(response?.data?.message || '获取详情数据失败！')
      })
      .catch(error => {
        modalFail(error?.response?.data?.message || '获取可选项数据失败！')
        reject(error)
      })
  })
}

export function fetchDetail(suffix, dataSrc, options) {
  const opts = options || {}
  const {
    params, key, url, targetKey = 'data.data',
  } = opts

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.serviceUrl}${suffix}` || url, {
        params,
      })
      .then(response => {
        console.log(response)
        if (response.data.status === 200) {
          // eslint-disable-next-line no-param-reassign
          dataSrc[key] = getDataWithKey(response, targetKey)
          resolve({ status: response.status, message: response.data.message })
        } else modalFail(response?.data?.message || '获取详情数据失败！')
      })
      .catch(error => {
        modalFail(error?.response?.data?.message || '获取详情数据失败！')
        reject(error)
      })
  })
}
