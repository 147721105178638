<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { VTextField } from 'vuetify/lib/components'

export default defineComponent({
  name: 'RadioTag',
  components: { VTextField },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const tagsItems = computed(() => props.items.map(item => {
      const row = {}

      if (item[props.itemText]) {
        row.checked = props.value === item[props.itemValue]
        row.itemText = item[props.itemText] || item
        row.itemValue = item[props.itemValue] !== undefined ? item[props.itemValue] : item
      } else {
        row.checked = props.value === item
        row.itemText = item
        row.itemValue = item
      }

      return row
    }))

    const inputValue = ref(props.value)

    function handleClick(item) {
      if (props.disabled) return

      if (item[props.itemValue] !== undefined) {
        inputValue.value = item[props.itemValue]
      } else {
        inputValue.value = item
      }

      emit('input', item[props.itemValue] !== undefined ? item[props.itemValue] : item)
    }

    return {
      tagsItems,
      inputValue,
      handleClick,
    }
  },
})
</script>

<template>
  <div
    class="tags-wrap"
    :class="disabled?'disabled': ''"
  >
    <div class="tags-list">
      <span
        v-for="(item, index) in tagsItems"
        :key="index"
        :class="item.checked ? 'active': ''"
        @click="() => handleClick(item.itemValue, index)"
      >{{ item.itemText }}</span>
    </div>
    <v-text-field
      v-model="inputValue"
      :rules="rules"
    ></v-text-field>
  </div>
</template>
<style lang="scss" scoped>
.tags-wrap {
  font-size: 14px;
  .tags-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span{
      border: 1px solid rgba($primary-shade--light, 0.22);
      padding: 4px 10px;
      margin-right: 10px;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 4px
    }
    span:hover{
      border-color: var(--v-primary-base);
      color: var(--v-primary-base);
    }
    span.active{
      border-color: var(--v-primary-base);
      color: var(--v-primary-base);
    }

  }
  .text--danger{
    color: var(--v-error-base)
  }
  ::v-deep .v-input{
    padding:0;
    margin-top: 4px;
    .v-messages, .v-text-field__details{
      min-height: 0;
    }
    .v-input__slot{
      display: none;
    }
  }
}

.tags-wrap.disabled{
  span,span:hover{
    border-color: rgba($primary-shade--light, 0.38);
    color: rgba($primary-shade--light, 0.38);
    cursor: default;
  }
  span.active{
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
  }
}
</style>
