const pages = [
  {
    path: '/project-plan',
    name: 'project-plan',
    component: () => import('@/views/projectPlans/Index.vue'),
    meta: {
      layout: 'content',
      pageTitle: '项目规划',
    },
  },

  // {
  //   path: '/month-plan',
  //   name: 'month-plan',
  //   component: () => import('@/views/monthPlan/Index.vue'),
  //   meta: {
  //     layout: 'content',
  //     pageTitle: '月度任务规划',
  //   },
  // },
  // {
  //   path: '/month-plan/month-plan-detail',
  //   name: 'month-plan-detail',
  //   component: () => import('@/views/monthPlanDetail/Index.vue'),
  //   meta: {
  //     layout: 'content',
  //     pageTitle: '月度任务规划',
  //   },
  // },

  // {
  //   path: '/project-schedule',
  //   name: 'project-schedule',
  //   component: () => import('@/views/projectSchedule/Index.vue'),
  //   meta: {
  //     layout: 'content',
  //     pageTitle: '项目进度',
  //   },
  // },
]

export default pages
