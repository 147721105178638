<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VText',
  props: {
    value: {
      type: String || Number,
      default: '',
    },
  },

  setup() {

  },
})
</script>

<template>
  <div
    v-if="value"
    style="word-break: break-all; white-space: pre-line;"
    v-text="value"
  ></div>
  <div v-else>
    -
  </div>
</template>
